import { Link } from "gatsby"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import Layout from "../components/layout/Layout"
import SEO from "../components/layout/SEO"
import { H1 } from "../styles/TextStyles"

interface ThankYouProps {}

const Success: FunctionComponent<ThankYouProps> = () => {
  return (
    <Layout>
      <SEO title="Sucess" />
      <Wrapper>
        <Title>Thank you for your submission.</Title>
        <Link to="/">Go back to the website</Link>
      </Wrapper>
    </Layout>
  )
}

export default Success

const Wrapper = styled.div`
  display: grid;
  gap: 30px;
  padding: 200px 80px;
  a {
    text-decoration: none;
    color: black;
    :hover {
      color: #5bccf6;
    }
  }
`
const Title = styled(H1)`
  font-weight: bold;
  @media (max-width: 640px) {
    font-size: 20px;
    line-height: 130%;
  }
`
